<template>
    <div class="safetyOfficer_page">
        <BannerImg :bannerImgUrl="formData.bannerImgUrl"></BannerImg>
        <div class="module1">
            <CourseTitle :title="formData.whyGetCertificateName"> </CourseTitle>
            <div class="content whyGetCertificate display">
                <div class="list" v-for="(item, index) in formData.safetyOfficerAbcWhyGetCertificates" :key="index">
                    <div class="title display">
                        <div class="reason_div" v-if="index == 0">理由一</div>
                        <div class="reason_div" v-if="index == 1">理由二</div>
                        <div class="reason_div" v-if="index == 2">理由三</div>
                        <div class="reason_div" v-if="index == 3">理由四</div>
                        <div class="reason_name">{{ item.whyGetCertificateName }}</div>
                    </div>
                    <div class="reason_cont">
                        <p>
                            {{ item.whyGetCertificateIntroduce }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="module2">
            <CourseTitle :title="formData.certificateTrainingContentName" :isWhite="1">
            </CourseTitle>
            <div class="content trainingContent flex">
                <div class="trainingContent_fl">
                    <div class="list display pointer" :class="activeIndex == index ? 'active' : ''" v-for="(
							item, index
						) in formData.safetyOfficerAbcCertificateTrainingContents" :key="index" @click="changeBtn(item, index)">
                        {{ item.certificateTrainingContentName }}
                    </div>
                </div>
                <div class="trainingContent_main">
                    <img :src="trainingContent.certificateTrainingContentImgUrl" alt="" />
                </div>
                <div class="trainingContent_fr">
                    <div class="list">
                        <div class="name">学习对象</div>
                        <div class="text">
                            {{ trainingContent.learningObject }}
                        </div>
                    </div>
                    <div class="list">
                        <div class="name">课程内容</div>
                        <div class="text">
                            {{ trainingContent.certificateTrainingContent }}
                        </div>
                    </div>
                    <div class="btn display pointer" @click="service">点击咨询更多</div>
                </div>
            </div>
        </div>
        <div class="module3">
            <CourseTitle :title="formData.certificateTemplateName"> </CourseTitle>
            <div class="content certificateTemplate">
                <div class="navber_div display">
                    <div @click="changeNav(item, index)" class="list pointer display"
                        :class="navIndex == index ? 'navList' : ''" v-for="(
							item, index
						) in formData.safetyOfficerAbcCertificateTemplates" :key="index">
                        {{ item.certificateTemplateName }}
                        <div class="line" v-if="navIndex == index"></div>
                    </div>
                </div>
                <div class="content_img">
                    <img :src="certificateTemplateImg" alt="" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BannerImg from '@/components/bannerImg'
import CourseTitle from '../components/courseTitle.vue'
export default {
    components: {
        BannerImg,
        CourseTitle,
    },
    data() {
        return {
            activeIndex: 0,
            navIndex: 0,
            trainingContent: {},
            certificateTemplateImg: '',
            formData: {},
        }
    },
    mounted() {
        this.init()
        document.documentElement.scrollTop = 0
    },
    methods: {
        //初始化数据
        init() {
            this.api.safetyOfficerApi().then((res) => {
                this.formData = res.data
                this.trainingContent =
                    res.data.safetyOfficerAbcCertificateTrainingContents[0]
                this.certificateTemplateImg =
                    res.data.safetyOfficerAbcCertificateTemplates[0].certificateTemplateImgUrl
            })
        },
        changeBtn(item, index) {
            this.activeIndex = index
            this.trainingContent = item
        },
        changeNav(item, index) {
            this.navIndex = index
            this.certificateTemplateImg = item.certificateTemplateImgUrl
        },
        //联系客服
        service() {
            document.getElementById('nb-frame-chat-box').style.display = 'block'
        },
    },
}
</script>
<style lang="scss" scoped>
.safetyOfficer_page {
    .module1 {
        height: 500px;
        background: url('./img/safetyOfficer/bg1.png') no-repeat;
        background-size: 100% 100%;

        .whyGetCertificate {
            margin-top: 41px;

            .list:nth-child(1) {
                margin-left: 0 !important;
            }

            .list {
                width: 290px;
                height: 230px;
                background: #fafafa;
                box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
                transform: skew(-10deg);
                margin-left: 20px;

                .title {
                    width: 290px;
                    height: 60px;
                    background: #003c7e;

                    div {
                        font-size: 22px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #f5f5f5;
                    }

                    .reason_div {
                        margin-right: 6px;
                    }

                    .reason_name {
                        color: #ff881e;
                    }
                }

                .reason_cont {
                    width: 100%;
                    height: 170px;
                    background: #fff;
                    padding: 34px;
                    box-sizing: border-box;

                    p {
                        width: 230px;
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333333;
                        line-height: 24px;
                    }
                }
            }
        }
    }

    .module2 {
        height: 750px;
        background: url('./img/safetyOfficer/bg2.png') no-repeat;
        background-size: 100% 100%;

        .trainingContent {
            width: 1200px;
            height: 506px;
            background: #ffffff;
            box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            margin-top: 41px;

            .trainingContent_fl {
                width: 218px;
                height: 506px;
                border-right: 1px solid #dddddd;

                .list:last-child {
                    border-bottom: none;
                }

                .list:nth-child(1) {
                    border-radius: 5px 0 0 0;
                }

                .active {
                    background: #ff881e !important;
                    color: white !important;
                }

                .list {
                    width: 100%;
                    height: 101px;
                    font-size: 22px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                    border-bottom: 1px solid #dddddd;
                    padding: 0px 30px;
                    box-sizing: border-box;
                    text-align: center;
                }
            }

            .trainingContent_main {
                margin: 22px 35px;

                img {
                    width: 600px;
                    height: 460px;
                }
            }

            .trainingContent_fr {
                padding-top: 37px;
                box-sizing: border-box;
                position: relative;

                .list {
                    margin-bottom: 36px;

                    .name {
                        font-size: 22px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #333333;
                        line-height: 22px;
                        margin-bottom: 15px;
                    }

                    .text {
                        width: 256px;
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333333;
                        line-height: 28px;
                    }
                }

                .btn {
                    width: 260px;
                    height: 48px;
                    background: #ff881e;
                    border-radius: 5px;
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #ffffff;
                    position: absolute;
                    left: 11px;
                    bottom: 44px;
                }
            }
        }
    }

    .module3 {
        height: 755px;
        background: url('./img/safetyOfficer/bg3.png') no-repeat;
        background-size: 100% 100%;

        .certificateTemplate {
            margin-top: 42px;

            .navber_div {
                padding-left: 77px;
                box-sizing: border-box;

                .list:nth-child(1) {
                    margin-left: 0 !important;
                    white-space: nowrap;
                }

                .list:nth-child(2) {
                    white-space: nowrap;
                }

                .list:nth-child(3) {
                    white-space: nowrap;
                }

                .list:nth-child(4) {
                    width: 198px;
                }

                .list:nth-child(5) {
                    width: 176px;
                }

                .navList {
                    font-weight: bold !important;

                    .line {
                        background: #003c7e !important;
                    }
                }

                .list {
                    width: 112px;
                    font-size: 22px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #003c7e;
                    line-height: 24px;
                    position: relative;
                    margin-left: 92px;
                    text-align: center;

                    .line {
                        width: 100%;
                        height: 3px;
                        background: #003c7e;
                        position: absolute;
                        left: 0;
                        bottom: -8px;
                    }
                }
            }

            .content_img {
                margin-top: 6px;

                img {
                    width: 1200px;
                    height: 480px;
                }
            }
        }
    }
}</style>